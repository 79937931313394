import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Overhead Squat 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`(pre open wod 2017)`}</em></p>
    <p>{`8:00 AMRAP of:`}</p>
    <p><strong parentName="p"><em parentName="strong">{`RX                                                        
 Scaled/Masters`}</em></strong></p>
    <p>{`30-Full Snatch (95/65)                   30-Power Snatch (75/55)`}</p>
    <p>{`15-Ring Muscle Ups                        15-Jumping Ring Pullups (12″
above head)`}</p>
    <p>{`20-Full Snatch (115/75)                   20-Power Snatch (95/65)`}</p>
    <p>{`10-Ring Muscle Ups                       10-Jumping Ring Pullups`}</p>
    <p>{`10-Full Snatch (135/95)                 10-Power Snatch (115/75)`}</p>
    <p>{`5-Ring Muscle Ups                          5-Jumping Ring Pullups`}</p>
    <p><em parentName="p">{`*`}{`compare to 1/21/17`}</em></p>
    <p><strong parentName="p">{`Max Test:`}</strong></p>
    <p>{`50 Calorie Assault Bike for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be adding a 9:00am class on Tuesdays and Thursdays starting
this Thursday, August 2nd.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Sunday, August 5th we are having our Ville get together at the
Barnetts house, everyone is invited!  We’ll watch the final day of the
CrossFit Games, eat, swim, and we’ll even have a few chances to workout
(with prizes for 1st place in each wod).  Call, text or email Daniel for
the address.  Family friendly so bring the kids!  The gym will be closed
for our party.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your 4 person (2 men/2 women) teams together now for True Grit
6!  RX and Scaled teams will battle it out for the 6th year at the Ville
for cash prizes and other goodies.  September 15th.  Register by
September 1st to ensure you get your t shirt on the day of the
competition.  If you want to compete but don’t have a team we’ll have a
signup sheet by the white board for each division.  We’ll need judges
too!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{` `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      